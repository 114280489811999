import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { AuthServiceWrapper } from './shared/services/auth.service';
import { environment } from '@environments/environment';
import { IState } from './shared/state';
import { CheckLogin } from './shared/state/auth/auth.actions';
import {
  getCurrentOrganisation,
  getLastSelectedOrganisation,
  getOrganisationChanging,
  getOrganisationLocale,
  getRegisterOrganisationPending,
  getRehydratePending,
  getUserOrganisations,
} from './shared/state/organisations';

import { MediaMatcher } from '@angular/cdk/layout';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { screens } from '@app/shared/utils/screen-query-wrapper';
import { ConvertPipe, ConvertPipeService } from 'cde-fe-convert-pipe';
import { IOrganisationListItem, IUser } from 'cde-fe-organization-registration-dialog';
import { getLocaleByAlpha2 } from 'country-locale-map';
import { IMaintenanceText, MaintenanceToast } from './shared/models/dashboard.model';
import { ICurrentOrganisation, RegistrationStatus } from './shared/models/organisation.model';
import { DashboardService } from './shared/services/dashboard.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { MachinesService } from './shared/services/machines.service';
import { RoutingStateService } from './shared/services/routing-state.service';
import { getPendingOrgSwitch, userSelector } from './shared/state/auth';
import { MachineImagesUrl } from './shared/state/machine-overview/machines.actions';
import { GetCurrentOrganisation, SetCurrentOrganisation } from './shared/state/organisations/organisations.actions';
import { getContentHeight } from './shared/state/ui';
import { SetContentHeight } from './shared/state/ui/ui.actions';
import { UpdateUserAppMetadata } from './shared/state/user/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConvertPipe],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private activatedRoute = inject(ActivatedRoute);
  private auth = inject(AuthServiceWrapper);
  private cdr = inject(ChangeDetectorRef);
  private convertPipeService = inject(ConvertPipeService);
  private dashboardService = inject(DashboardService);
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter<Date>);
  private localStorageService = inject(LocalStorageService);
  private machineService = inject(MachinesService);
  private router = inject(Router);
  private store = inject<Store<IState>>(Store<IState>);
  private translator = inject(TranslateService);
  public routingState = inject(RoutingStateService);

  @ViewChildren('toastsWrapper') toastsWrapper: QueryList<ElementRef>;
  inlineToastSubject = new BehaviorSubject<{
    status: boolean;
    timeToLeave: number;
  } | null>({
    status: true,
    timeToLeave: 0,
  });
  inlineToastSubject$ = this.inlineToastSubject.asObservable();

  isExpanded = false;
  orgRegistered: boolean;
  orgIsRejected = false;

  scheduledMaintenanceWork = false;
  maintenanceToasts: MaintenanceToast[] = [];

  public pendingOrgSwitch$ = this.store.select(getPendingOrgSwitch);
  public pendingOrgRegistration$ = this.store.select(getRegisterOrganisationPending);
  public getOrganisationChanging$ = this.store.select(getOrganisationChanging);
  public getRehydratePending$ = this.store.select(getRehydratePending);

  public validatedOrg = true;
  public hidevalidatedOrgAlertBanner: boolean;
  public hideRejectedOrgAlertBanner = false;

  public orgChanging = false;
  public contentHeight: string;

  private smQuery: MediaQueryList;
  private currentOrganisation$ = this.store.select(getCurrentOrganisation);
  private orgChange$ = this.store.select(getLastSelectedOrganisation);
  private subscription = new Subscription();
  private orgChangeWatcher: Subscription;
  private userWatcher: Subscription;
  private userSubjectWatcher: Subscription;
  private initListener: () => void;
  private orgId: string;

  constructor() {
    const media = inject(MediaMatcher);

    this.smQuery = media.matchMedia(`(max-width: ${(screens as any).sm.max})`);
  }

  public ngAfterViewInit(): void {
    this.store.dispatch(new CheckLogin());

    this.applyContentWrapperHeight(this.toastsWrapper);

    this.toastsWrapper.changes.subscribe((elements: QueryList<ElementRef>) => {
      this.applyContentWrapperHeight(elements);
    });
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getContentHeight).subscribe((contentHeight) => {
        this.contentHeight = contentHeight;
        this.cdr.detectChanges();
      })
    );
    // sets default translation based on user profile
    this.userSubjectWatcher = this.auth.userProfileSubject$.subscribe((user: IUser) => {
      let lang = user.user_metadata.language;
      lang = lang ? lang : 'de';
      this.translator.use(lang);
      this.convertPipeService.setLanguage(lang);
      this.initializeUcUiLanguage(lang);
    });

    this.subscription.add(
      this.getOrganisationChanging$.subscribe((isChanging) => {
        this.orgChanging = isChanging;
      })
    );

    this.subscription.add(
      this.currentOrganisation$.subscribe((currentOrg: ICurrentOrganisation | null) => {
        if (currentOrg) {
          const locale = getLocaleByAlpha2(currentOrg.country.toUpperCase());
          moment.locale(locale);

          this.validatedOrg = currentOrg.validated;
          this.convertPipeService.setUnitSystem(currentOrg.unitSystem);
          this.convertPipeService.setCountry(currentOrg.country);
          this.orgRegistered = currentOrg.registered;
          this.orgIsRejected = currentOrg.registrationStatus === RegistrationStatus.REJECTED;
          this.orgId = currentOrg.auth0Id;
          this.store.dispatch(new MachineImagesUrl());
        } else {
          this.getCurrentOrganisation();
        }

        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      combineLatest([this.currentOrganisation$, this.store.select(getUserOrganisations), this.activatedRoute.queryParams]).subscribe(
        ([currentOrganisation, organisations, queryParams]) => {
          // get auth0 org ID from the URL query parameters
          if (queryParams?.orgId == null) {
            return;
          }

          // check if the user has the given org in his list of organisations
          const orgId = queryParams.orgId as string;
          const hasOrganisation = organisations.some((org) => org.auth0Id === orgId);
          if (!hasOrganisation) {
            return;
          }

          // reset the current organisation if the given org is different from
          // the current organisation. when the user navigates from farm side it
          // might be possible that the current org is null and not set. Then
          // the current org needs to be initialised according to the query
          // param
          if (currentOrganisation?.auth0Id !== orgId) {
            this.store.dispatch(new SetCurrentOrganisation({ auth0Id: orgId }));
          }
        }
      )
    );

    this.subscription.add(
      this.machineService.unit$.subscribe((zone: string) => {
        this.convertPipeService.setTimeZone(zone);
      })
    );

    this.subscription.add(
      this.localStorageService.observeKey('maintenanceTexts').subscribe((maintenanceTexts: IMaintenanceText[]) => {
        this.setMaintenanceToasts(maintenanceTexts);
      })
    );

    // central place to set the global locale for the dateAdapter singleton
    this.subscription.add(
      this.store.select(getOrganisationLocale).subscribe((locale) => {
        this.dateAdapter.setLocale(locale);
      })
    );

    this.showMigrationLandingPageOnFirstLogin();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.orgChangeWatcher?.unsubscribe();
    this.userWatcher?.unsubscribe();
    this.userSubjectWatcher?.unsubscribe();

    window.removeEventListener('UC_UI_INITIALIZED', this.initListener);
  }

  public getActionFromNavbar(event: boolean): void {
    this.isExpanded = event;
    this.dashboardService.checkNavbarStatus(this.isExpanded);
  }

  public handleClickedEvent($event: boolean) {
    if (this.smQuery.matches && this.isExpanded) {
      this.isExpanded = !this.isExpanded;
      this.dashboardService.checkNavbarStatus(this.isExpanded);
    }
  }

  getCurrentOrganisation() {
    // if current org is reset to null the subscription might be built up again
    // and thus the old one needs to be unsubscribed first
    this.orgChangeWatcher?.unsubscribe();
    this.orgChangeWatcher = this.orgChange$.subscribe((data: Partial<IOrganisationListItem | null>) => {
      if (data && data.auth0Id) {
        this.store.dispatch(new GetCurrentOrganisation(data.auth0Id));
      }
    });
  }

  closeAlertWarning(index: number) {
    const maintenanceTexts: any[] = this.localStorageService.getItem('maintenanceTexts');
    this.maintenanceToasts = [];
    if (maintenanceTexts.length > 0) {
      if (maintenanceTexts.length === 1) {
        this.localStorageService.removeItem('maintenanceTexts');
      } else {
        maintenanceTexts.splice(index, 1);
        this.localStorageService.setItem('maintenanceTexts', maintenanceTexts);
      }
    }
    this.cdr.detectChanges();
    this.applyContentWrapperHeight(this.toastsWrapper);
  }

  closeUnverifiedOrgAlertBanner() {
    this.hidevalidatedOrgAlertBanner = true;
    this.cdr.detectChanges();
    this.applyContentWrapperHeight(this.toastsWrapper);
  }

  closeRejectedOrgAlertBanner() {
    this.hideRejectedOrgAlertBanner = true;
    this.cdr.detectChanges();
    this.applyContentWrapperHeight(this.toastsWrapper);
  }

  setMaintenanceTextsInLocalStorage(maintenanceTexts: IMaintenanceText[]) {
    maintenanceTexts.forEach((maintenanceText, index) => localStorage.setItem(`maintenanceText${index}`, JSON.stringify(maintenanceText)));
  }

  deleteOrg() {
    window.open(`${environment.claasIdDashboardBaseUrl}/organisation-data?orgId=${this.orgId}&startDeletion=true`, '_blank');
  }

  private setMaintenanceToasts(maintenanceTexts: IMaintenanceText[]) {
    maintenanceTexts?.forEach((maintenanceText) => {
      const newMaintenanceToast = {
        ...maintenanceText,
        ...this.getMaintenanceToastType(maintenanceText.viewType),
      };

      const isDuplicate = this.maintenanceToasts.some((toast) => JSON.stringify(toast) === JSON.stringify(newMaintenanceToast));

      if (!isDuplicate) {
        this.maintenanceToasts.push(newMaintenanceToast);
      }
    });
    this.cdr.detectChanges();
  }

  private getMaintenanceToastType(viewType: string): { type: 'error' | 'warning' | 'info' | 'success' } {
    const toastTypes: Record<string, { type: 'error' | 'warning' | 'info' | 'success' }> = {
      /* eslint-disable @typescript-eslint/naming-convention */
      'cc3-teaser-alert-warning': { type: 'warning' },
      'cc3-teaser-alert-info': { type: 'info' },
      'cc3-teaser-alert-error': { type: 'error' },
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return toastTypes[viewType];
  }

  private applyContentWrapperHeight(toastsWrapper: QueryList<ElementRef>) {
    toastsWrapper.forEach((element) => {
      const elementHeight = element.nativeElement.offsetHeight;
      const adjustedHeight = elementHeight > 0 ? elementHeight + 48 : 0;
      const height = adjustedHeight ? `calc(100% - ${adjustedHeight}px)` : '100%';
      this.store.dispatch(new SetContentHeight(height));
    });
  }

  private showMigrationLandingPageOnFirstLogin() {
    // goto migration landing page after first login. Routing needs to be done
    // in setTimeout because router is not initialized completely in ngOnInit of
    // root component
    this.userWatcher = this.store.select(userSelector).subscribe((user) => {
      if (user == null) {
        return;
      }

      if (user.app_metadata.first_login == null || user.app_metadata.first_login.length === 0) {
        this.store.dispatch(
          new UpdateUserAppMetadata({
            key: 'first_login',
            value: new Date().toISOString(),
          })
        );

        // only go to migration landing page if the route is not already active
        if (!this.routingState.isMigrationPage()) {
          setTimeout(() => {
            void this.router.navigate(['migration']);
          });
        }
      }
    });
  }

  private initializeUcUiLanguage(language: string) {
    if (!language) {
      return;
    }

    language = language?.toLocaleLowerCase();
    // usercentrics only knows en
    if (language === 'en-us' || language === 'en-gb') {
      language = 'en';
    }

    if (typeof UC_UI !== 'undefined' && UC_UI?.isInitialized()) {
      UC_UI.updateLanguage(language);
    } else {
      this.initListener = () => UC_UI.updateLanguage(language);
      window.addEventListener('UC_UI_INITIALIZED', this.initListener);
    }
  }
}
