
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, finalize, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceCallResponse } from '@app/shared/models/service-call.model';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';

import * as RootState from '..';
import * as MigrationActions from './migration.actions';

import { MigrationService } from '@app/shared/services/migration.service';
import { ICreateMigrationIntentRequest, ICreateMigrationRequest, IFieldBackupStateResponse, IMigration, IMigrationIntent, IMigrationLicenses, IMigrationMachines, IOrganizationMigrationProposal, ITelematicsCustomers } from '@app/shared/models/migration.model';

@Injectable()
export class MigrationEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private migrationService = inject(MigrationService);
  private router = inject(Router);
  private alertService = inject(AlertService);
  private translateService = inject(TranslateService);
  createMigrationIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.CREATE_MIGRATION_INTENT),
      map((action: MigrationActions.CreateMigrationIntent) => action.createMigrationIntentRequest),
      mergeMap((createMigrationIntentRequest: ICreateMigrationIntentRequest) =>
        this.migrationService.createMigrationIntent(createMigrationIntentRequest).pipe(
          map((migrationIntent: IMigrationIntent) => new MigrationActions.CreateMigrationIntentSuccess(migrationIntent)),
          catchError((error) => of(new MigrationActions.CreateMigrationIntentFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.CreateMigrationIntentComplete());
          })
        )
      )
    )
  );

  createMigrationIntentError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.CREATE_MIGRATION_INTENT_FAILURE),
        map((action: MigrationActions.CreateMigrationIntentFailure) => action.error as ServiceCallResponse),
        tap((error: ServiceCallResponse) => {
          if (error?.failCode === 401) {
            this.alertService.showError(
              this.translateService.instant('migration.accounts-and-data.login.snackbar-error-message'),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          } else if (error?.failCode === 403) {
            this.alertService.showError(
              this.translateService.instant('migration.accounts-and-data.login.telematics-account-with-no-migratable-machines'),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          } else {
            this.alertService.showError(
              this.translateService.instant('global.error_occurred'),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          }
        })
      ),
    { dispatch: false }
  );

  // get TM customer data when migration intent ID
  createMigrationIntentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.CREATE_MIGRATION_INTENT_SUCCESS),
      map(
        (action: MigrationActions.CreateMigrationIntentSuccess) =>
          new MigrationActions.GetTelematicsCustomers(action.migrationIntent.id, action.migrationIntent.migratableCC20)
      )
    )
  );

  getTelematicsCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_TELEMATICS_CUSTOMERS),
      map((action: MigrationActions.GetTelematicsCustomers) => [action.intentId, action.migratableCC20] as [string, boolean]),
      mergeMap(([intentId, migratableCC20]: [string, boolean]) =>
        this.migrationService.getTelematicsCustomers(intentId).pipe(
          map(
            (telematicsCustomers: ITelematicsCustomers) =>
              new MigrationActions.GetTelematicsCustomersSuccess(telematicsCustomers, intentId, migratableCC20)
          ),
          catchError((error) => of(new MigrationActions.GetTelematicsCustomersFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.GetTelematicsCustomersComplete());
          })
        )
      )
    )
  );

  getTmCustomersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.GET_TELEMATICS_CUSTOMERS_SUCCESS),
        tap((action: MigrationActions.GetTelematicsCustomersSuccess) => {
          const telematicsCustomers = action.telematicsCustomers;
          if (
            telematicsCustomers != null &&
            telematicsCustomers.migratedTelematicsCustomers?.length > 0 &&
            (telematicsCustomers.migratableTelematicsCustomers == null || telematicsCustomers.migratableTelematicsCustomers.length === 0) &&
            !action.migratableCC20
          ) {
            void this.router.navigate(['migration', 'finish']);
            return;
          }

          void this.router.navigate(['migration', 'migrate-accounts-data', action.intentId]);
        })
      ),
    { dispatch: false }
  );

  getMigrationMachines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_MIGRATION_MACHINES),
      map((action: MigrationActions.GetMigrationMachines) => [action.intentId, action.telematicsCustomer] as [string, number | null]),
      switchMap(([intentId, telematicsCustomer]) =>
        this.migrationService.getMigrationMachines(intentId, telematicsCustomer).pipe(
          map((migrationMachines: IMigrationMachines) => new MigrationActions.GetMigrationMachinesSuccess(migrationMachines)),
          catchError((error) => of(new MigrationActions.GetMigrationMachinesFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.GetMigrationMachinesComplete(telematicsCustomer));
          })
        )
      )
    )
  );

  getMigrationLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_MIGRATION_LICENSES),
      map((action: MigrationActions.GetMigrationLicenses) => [action.intentId, action.telematicsCustomer] as [string, number | null]),
      switchMap(([intentId, telematicsCustomer]) =>
        telematicsCustomer == null
          ? of(new MigrationActions.GetMigrationLicensesComplete(telematicsCustomer))
          : this.migrationService.getMigrationLicenses(intentId, telematicsCustomer).pipe(
            map((migrationLicenses: IMigrationLicenses) => new MigrationActions.GetMigrationLicensesSuccess(migrationLicenses)),
            catchError((error) => of(new MigrationActions.GetMigrationLicensesFailure(error))),
            finalize(() => {
              this.store.dispatch(new MigrationActions.GetMigrationLicensesComplete(telematicsCustomer));
            })
          )
      )
    )
  );

  createMigration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.CREATE_MIGRATION),
      map((action: MigrationActions.CreateMigration) => action.createMigrationRequest),
      mergeMap((createMigrationRequest: ICreateMigrationRequest) =>
        this.migrationService.createMigration(createMigrationRequest).pipe(
          map((migration: IMigration) => new MigrationActions.CreateMigrationSuccess(migration)),
          catchError((error) => of(new MigrationActions.CreateMigrationFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.CreateMigrationComplete());
          })
        )
      )
    )
  );

  createMigrationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.CREATE_MIGRATION_SUCCESS),
        map((action: MigrationActions.CreateMigrationSuccess) => action.migration),
        tap((migration: IMigration) => {
          void this.router.navigate(['migration', 'finish', migration.id]);
        })
      ),
    { dispatch: false }
  );

  getMigrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_MIGRATIONS),
      mergeMap(() =>
        this.migrationService.getMigrations().pipe(
          map((migrations: IMigration[]) => new MigrationActions.GetMigrationsSuccess(migrations)),
          catchError((error) => of(new MigrationActions.GetMigrationsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.GetMigrationsComplete());
          })
        )
      )
    )
  );

  getOrganizationMigrationProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_ORGANIZATION_MIGRATION_PROPOSAL),
      mergeMap(() =>
        this.migrationService.getOrganizationMigrationProposal().pipe(
          map(
            (migrationProposal: IOrganizationMigrationProposal) =>
              new MigrationActions.GetOrganizationMigrationProposalSuccess(migrationProposal)
          ),
          catchError((error) => of(new MigrationActions.GetOrganizationMigrationProposalFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.GetOrganizationMigrationProposalComplete());
          })
        )
      )
    )
  );

  getFieldBackupState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.GET_FIELD_BACKUP_STATE),
      map((action: MigrationActions.GetFieldBackupState) => [action.intentId, action.telematicsCustomer] as [string, number]),
      mergeMap(([intentId, telematicsCustomer]) =>
        this.migrationService.getFieldBackupState(intentId, telematicsCustomer).pipe(
          map((fieldBackupState: IFieldBackupStateResponse) => new MigrationActions.GetFieldBackupStateSuccess(fieldBackupState)),
          catchError((error) => of(new MigrationActions.GetFieldBackupStateFailure(error))),
          finalize(() => {
            this.store.dispatch(new MigrationActions.GetFieldBackupStateComplete());
          })
        )
      )
    )
  );

  private alertOptions = TOASTR_ALERT_OPTIONS;
}
